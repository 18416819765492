
import { Options, Vue } from 'vue-class-component'
import theMemberRegistration from '@/components/Frontend/MemberRegistration/index.vue'

@Options({
  components: {
    theMemberRegistration
  }
})

export default class MemberRegistration extends Vue {}
