import { Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { createWebHistory } from 'vue-router'
import CommonFunctions, { countryList, defaultLicenseId, defaultJuniorLicenseId } from '@/components/Utility/Common'
import BaseFunctions, { defaultAdminBackendGroupId, defaultClubId } from '@/components/Utility/Base'
import ByPostDataService from '@/services/ByPostDataService'
import UsersDataService from '@/services/UsersDataService'
import MembersDataService from '@/services/MembersDataService'
import ForeignPlayersDataService from '@/services/ForeignPlayersDataService'
// import { History } from '@/services/HistoryDataService'
import { Products } from '@/services/ProductsDataService'
import ByPost from '@/types/ByPost'
import SysForeignPlayer from '@/types/SysForeignPlayer'
import SysUser from '@/types/SysUser'
import SysMember, { ClubRoleType, SharePermissionType } from '@/types/SysMember'
import SysProduct from '@/types/SysProduct'
import SysCart from '@/types/SysCart'

type foreingPlayerDataType = { foreignPlayerFirstName: string; foreignPlayerLastName: string; foreignPlayerEmail: string; foreignPlayerParadart: boolean; foreignPlayerBirthDate: string; }
type memberDataType = { memberFirstName: string; memberLastName: string; memberUserName: string; memberPassword: string; memberBirthDate: string; memberParaDart: boolean; memberAddress: string; memberZipCode: string; memberCity: string; memberTelephone: string; memberEmail: string; }
type dataReturnType = { memberData: memberDataType; foreignPlayerData: foreingPlayerDataType; error: any; }
type productDataType = { productStatus: boolean; productName: string; productDescription: string; productPrice: number; productDateFrom: string; productDateTo: string; }

export default class theMemberRegistration extends Vue {
  error: any = null
  private cityZipCode: ByPost[] = []
  private checkUserNames: SysUser[] = []
  private createdMember = {} as SysMember
  private submitted = false
  private languageValue = 1
  private nationalityValue = 1
  private repeatPassword = ''
  private tempProduct = {} as SysProduct
  private seniorLicenseProduct = {} as SysProduct
  private juniorLicenseProduct = {} as SysProduct
  productData: productDataType = { productStatus: false, productName: '', productDescription: '', productPrice: 0, productDateFrom: '', productDateTo: '' }
  foreignPlayerData: foreingPlayerDataType = { foreignPlayerFirstName: '', foreignPlayerLastName: '', foreignPlayerEmail: '', foreignPlayerParadart: false, foreignPlayerBirthDate: '' }
  memberData: memberDataType = { memberFirstName: '', memberLastName: '', memberUserName: '', memberPassword: '', memberBirthDate: '', memberParaDart: false, memberAddress: '', memberZipCode: '', memberCity: '', memberTelephone: '', memberEmail: '' }
  memberGenderValue = { danish: 'Vælg her', english: 'Choose here', id: 0 }
  memberGenderOptions: { danish: string, english: string, id: number }[] = [{ danish: 'Vælg her', english: 'Choose here', id: 0 }, { danish: 'Dame', english: 'Female', id: 1 }, { danish: 'Herre', english: 'Male', id: 2 }]
  foreignPlayerGenderValue = { danish: 'Vælg her', english: 'Choose here', id: 0 }
  foreignPlayerGenderOptions: { danish: string, english: string, id: number }[] = [{ danish: 'Vælg her', english: 'Choose here', id: 0 }, { danish: 'Dame', english: 'Female', id: 1 }, { danish: 'Herre', english: 'Male', id: 2 }]
  private currentByPostId = 0
  private inhibitUpdateCityInfo = false
  private cityZipCodeInputFocus = 2 // Used to track which watch-tied input fields has the current input focus. Value 1 = city input field is in focus, value 2 = postal code input field is in focus.
  lastCreatedUserId = 0
  lastCreatedMemberId = 0
  currentHighestLicenseNumber = 0
  memberStart = ''
  countryStringValue = ''
  countryListOptions = countryList.map(function (item) { return item.name })
  webHistory = createWebHistory()
  nowDate = (new Date()).toISOString().split('T')[0]
  seasonHalfWay = (new Date(this.nowDate)).getFullYear() + '-07-01'
  seasonEnd = (new Date(this.nowDate)).getFullYear() + '-12-01'
  private userNameAvailable: boolean | null = null
  newMember = {} as SysMember

  readonly name : string = 'MemberRegistrations'
  $Notification: any
  $Message: any
  data (): dataReturnType {
    return {
      error: this.error,
      memberData: this.memberData,
      foreignPlayerData: this.foreignPlayerData
    }
  }

  @Watch('memberData.zipCode')
  onZipCodeChange (zipCodeValue: string) : void {
    if (this.cityZipCodeInputFocus === 2 && this.inhibitUpdateCityInfo === false && !isNaN(Number(zipCodeValue))) {
      this.updateCityInputFieldDataFromPostalCodeInputFieldData(zipCodeValue)
    }
  }

  @Watch('memberData.city')
  onCityChange (cityValue: string) : void {
    if (this.cityZipCodeInputFocus === 1 && cityValue !== null) {
      this.updatePostalCodeInputFieldDataFromCityInputFieldData(cityValue)
    }
  }

  @Watch('cityZipCodeInputFocus')
  onCityZipCodeFocusChange (inputFocus: number) : void {
    if (inputFocus === 1) {
      if (!isNaN(Number(this.memberData.memberZipCode))) {
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.memberData.memberZipCode)
      }
    } else if (inputFocus === 2) {
      if (this.memberData.memberCity !== null) {
        this.updatePostalCodeInputFieldDataFromCityInputFieldData(this.memberData.memberCity)
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.memberData.memberZipCode)
      }
    }
  }

  public updatePostalCodeInputFieldDataFromCityInputFieldData (cityData: string) : void {
    if (cityData !== undefined && cityData !== null && cityData.length > 1) {
      ByPostDataService.findByCity(cityData, '', '1')
        .then((response) => {
          this.cityZipCode = response.data

          if (this.cityZipCode === undefined || this.cityZipCode === null || this.cityZipCode[0] === undefined) {
            this.currentByPostId = 0
          } else {
            this.inhibitUpdateCityInfo = true
            this.currentByPostId = (this.cityZipCode[0].id !== null ? Number(this.cityZipCode[0].id) : 0)
            console.log('Current bypost Id : ' + this.currentByPostId)
            this.memberData.memberZipCode = (this.cityZipCode[0].id !== null ? (this.cityZipCode[0].bypost_postnummer.toString()) : '')
            this.inhibitUpdateCityInfo = false
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public updateCityInputFieldDataFromPostalCodeInputFieldData (postalCodeData: string) : void {
    if (postalCodeData !== undefined && postalCodeData !== null && Number(postalCodeData) > 799 && Number(postalCodeData) < 9999) {
      ByPostDataService.findByPostalnumber(Number(postalCodeData), '', '1')
        .then((response) => {
          this.cityZipCode = response.data

          if (this.cityZipCode === undefined || this.cityZipCode === null || this.cityZipCode[0] === undefined) {
            this.currentByPostId = 0
          } else {
            this.currentByPostId = (this.cityZipCode[0].id !== null ? Number(this.cityZipCode[0].id) : 0)
            console.log('Current bypost Id : ' + this.currentByPostId)
            this.memberData.memberCity = (this.cityZipCode[0].id !== null ? (this.cityZipCode[0].bypost_by) : '')
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public checkUserName () : any {
    let users: SysUser[] = []

    if (!(this.memberData.memberUserName.trim().length > 2)) {
      return this.$Message.warning({ text: this.languageValue === 1 ? 'Brugernavnet er for kort' : 'The Username is too short' })
    }

    UsersDataService.getAll('', null, `username=${this.memberData.memberUserName}`)
      .then((response) => {
        users = response.data
        if (users.length > 0) {
          this.userNameAvailable = false
          this.$Notification.warning({
            title: this.languageValue === 1 ? 'Brugernavnet er allerede taget' : 'The Username have already been taken'
          })
        } else {
          this.userNameAvailable = true
          this.$Notification.success({
            title: this.languageValue === 1 ? 'Brugernavnet er ledigt' : 'The Username is Available'
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public toDanishDateString (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString)
  }

  public testLegalEmail (emailAdress: string) : boolean {
    return BaseFunctions.isEmail(emailAdress)
  }

  public passwordTest (passwordToBeTested: string) : boolean {
    return CommonFunctions.strongPasswordTest(passwordToBeTested)
  }

  public newCreateMember () : void {
    this.memberData = { memberFirstName: '', memberLastName: '', memberUserName: '', memberPassword: '', memberBirthDate: '', memberParaDart: false, memberAddress: '', memberZipCode: '', memberCity: '', memberTelephone: '', memberEmail: '' }
    this.memberGenderValue = { danish: 'Vælg her', english: 'Choose here', id: 0 }
  }

  public newCreateForeignPlayer () : void {
    this.foreignPlayerData = { foreignPlayerFirstName: '', foreignPlayerLastName: '', foreignPlayerEmail: '', foreignPlayerParadart: false, foreignPlayerBirthDate: '' }
    this.foreignPlayerGenderValue = { danish: 'Vælg her', english: 'Choose here', id: 0 }
    this.countryStringValue = ''
  }

  public clearInput () : void {
    this.newCreateMember()
    this.newCreateForeignPlayer()

    this.submitted = false

    CommonFunctions.scrollToTop()
  }

  public createForeignPlayer () : void {
    console.log('Creating foreign player')

    if (
      this.foreignPlayerData.foreignPlayerFirstName.length > 1 &&
      this.foreignPlayerData.foreignPlayerLastName.length > 1 &&
      this.testLegalEmail(this.foreignPlayerData.foreignPlayerEmail) &&
      this.foreignPlayerData.foreignPlayerBirthDate.length > 1 &&
      (this.foreignPlayerGenderValue.id === 1 || this.foreignPlayerGenderValue.id === 2) &&
      (this.countryStringValue !== '' && this.countryStringValue !== 'Denmark')) {
      const createForeignPlayerData = {
        ud_spiller_status: true,
        koen_id: this.foreignPlayerGenderValue.id,
        ud_foedselsdag: this.foreignPlayerData.foreignPlayerBirthDate,
        ud_spiller_fornavn: this.foreignPlayerData.foreignPlayerFirstName,
        ud_spiller_efternavn: this.foreignPlayerData.foreignPlayerLastName,
        ud_spiller_paradart: this.foreignPlayerData.foreignPlayerParadart,
        ud_spiller_mail: this.foreignPlayerData.foreignPlayerEmail,
        ud_spiller_land: this.countryStringValue
      }

      ForeignPlayersDataService.create(createForeignPlayerData)
        .then((response) => {
          console.log(response.data)
          this.submitted = true

          CommonFunctions.scrollToTop()
        })
        .catch((err) => {
          this.error = err
        })
    }

    if (this.countryStringValue === 'Denmark') {
      this.$Message.danger({ text: (this.languageValue === 1 ? 'Hvis du er fra Danmark, så skal du registrer dig som en dansk spiller' : 'If you are from Denmark, you must register yourself as a danish player') })
    }
  }

  public retrieveLicenseProduct () : void {
    const licenseProductParameter = 'id=' + defaultLicenseId.toString() + '&id=' + defaultJuniorLicenseId.toString()
    let tempLicense: SysProduct[] = []

    Products.ProductsDataService.getAll('', null, licenseProductParameter)
      .then((response) => {
        tempLicense = response.data
        const tempSeniorLicense = tempLicense.find(element => element.id === defaultLicenseId)
        const tempJuniorLicense = tempLicense.find(element => element.id === defaultJuniorLicenseId)

        if (tempSeniorLicense !== undefined && tempSeniorLicense !== null && tempJuniorLicense !== undefined && tempJuniorLicense !== null) {
          this.seniorLicenseProduct = tempSeniorLicense
          this.juniorLicenseProduct = tempJuniorLicense
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public createMember () : void {
    let tempUserNames: SysUser[] = []
    let highestLicenseNumber = 0
    let tempMember: SysMember[] = []

    const getLicenseNumber = async () => {
      await MembersDataService.getAll('medlem_licens:desc', { slicemode: 0, start: 0, limit: 1, page: 0, pagesize: 0, totalcount: 0 })
        .then((response) => {
          tempMember = response.data
          highestLicenseNumber = tempMember[0].medlem_licens
        })
        .catch((err) => {
          this.error = err
        })
    }

    const checkLegalUserName = async (userName: string) => {
      await UsersDataService.getAll('', null, `username=${userName}`)
        .then((response) => {
          tempUserNames = response.data
        })
        .catch((err) => {
          this.error = err
        })
    }

    if (!(this.memberData.memberFirstName.length > 1) || !(this.memberData.memberLastName.length > 1) || !(this.memberData.memberUserName.length > 1) || !(this.passwordTest(this.memberData.memberPassword)) || this.repeatPassword !== this.memberData.memberPassword || !(this.memberData.memberBirthDate !== '') || !(this.testLegalEmail(this.memberData.memberEmail)) || !(this.memberGenderValue.id !== 0)) {
      return this.$Message.warning({ text: 'Fejl: Ikke alle felter er udfyldt' })
    }

    const promises = []
    promises.push(getLicenseNumber())
    promises.push(checkLegalUserName(this.memberData.memberUserName))

    Promise.all(promises)
      .then(() => {
        if (tempUserNames.length > 0) {
          return this.$Message.danger({ text: this.languageValue === 1 ? 'Brugernavnet er allerede valgt, vælg et andet' : 'The Username is already taken, choose another' })
        }

        const createOrderExtraData = {
          type: 1,
          memberUserName: this.memberData.memberUserName,
          memberPassword: this.memberData.memberPassword,
          memberEmail: this.memberData.memberEmail,
          memberFirstName: this.memberData.memberFirstName,
          memberLastName: this.memberData.memberLastName,
          memberTelephone: (this.memberData.memberTelephone === undefined ? 0 : Number(this.memberData.memberTelephone)),
          memberAddress: this.memberData.memberAddress,
          cityZipId: this.currentByPostId,
          memberBirthDate: this.memberData.memberBirthDate,
          memberGenderValueId: this.memberGenderValue.id,
          clubId: defaultClubId,
          memberLicenseEnd: this.newLicenseEndDate(),
          sharePermissionType: SharePermissionType.Nej
        }

        this.addLicenseToCart(createOrderExtraData)

        this.submitted = true
        CommonFunctions.scrollToTop()

        // const createUserData = {
        //   blocked: false,
        //   username: this.memberData.memberUserName,
        //   password: this.memberData.memberPassword,
        //   email: this.memberData.memberEmail,
        //   firstname: this.memberData.memberFirstName,
        //   lastname: this.memberData.memberLastName,
        //   phone: (this.memberData.memberTelephone === undefined ? 0 : Number(this.memberData.memberTelephone)),
        //   usrgroup: defaultAdminBackendGroupId,
        //   provider: 'local',
        //   role: '3',
        //   confirmed: true
        // }

        // const createUserData = {
        //   bruger_status: true,
        //   bruger_brugernavn: this.memberData.memberUserName,
        //   bruger_password: this.memberData.memberPassword,
        //   bruger_email: this.memberData.memberEmail,
        //   bruger_fornavn: this.memberData.memberFirstName,
        //   bruger_efternavn: this.memberData.memberLastName,
        //   bruger_telefon: (this.memberData.memberTelephone === undefined ? '' : this.memberData.memberTelephone),
        //   bruger_brugergruppe_id: defaultAdminBackendGroupId
        // }

        // UsersDataService.create(createUserData)
        //   .then((response) => {
        //     console.log('User created: ' + response.statusText)
        //     const userId = response.data.id

        //     const createMemberData = {
        //       medlem_status: true,
        //       medlem_vejnummer: this.memberData.memberAddress,
        //       medlem_foedselsdag: this.memberData.memberBirthDate,
        //       medlem_begyndelse: new Date(),
        //       medlem_licens: highestLicenseNumber + 1,
        //       medlem_paradart: false,
        //       bypost_id: this.currentByPostId,
        //       user_id: userId,
        //       koen_id: this.memberGenderValue.id,
        //       klubber_id: defaultClubId,
        //       medlem_klubrolle: ClubRoleType.Medlem,
        //       medlem_licens_slut: new Date(this.nowDate),
        //       medlem_visoplysninger: SharePermissionType.Nej
        //     }

        //     MembersDataService.create(createMemberData)
        //       .then((response) => {
        //         console.log('Member created: ' + response.statusText)
        //         this.newMember = response.data

        //         this.addLicenseToCart()

        //         const createHistoryEntry = {
        //           medlemshistorik_handling: 'Oprettet d. ' + this.toDanishDateString(this.newMember.created_at),
        //           medlem_id: Number(response.data.id)
        //         }

        //         History.HistoryDataService.create(createHistoryEntry)
        //           .then((response) => {
        //             console.log('Create member history entry: ' + response.statusText)
        //             this.submitted = true

        //             CommonFunctions.scrollToTop()
        //           })
        //           .catch((err) => {
        //             this.error = err
        //           })
        //       })
        //   })
        //   .catch((err) => {
        //     this.error = err
        //   })
      })
      .catch((err) => {
        this.error = err
      })
  }

  // public createMember () : void {
  //   console.log('Checking if username is unique')
  //   if (
  //     this.memberData.memberUserName.length > 1 &&
  //     this.memberData.memberFirstName.length > 1 &&
  //     this.memberData.memberLastName.length > 1 &&
  //     !isNaN(Number(this.memberData.memberTelephone)) &&
  //     this.memberData.memberTelephone.length === 8 &&
  //     this.testLegalEmail(this.memberData.memberEmail) &&
  //     this.passwordTest(this.memberData.memberPassword) &&
  //     this.memberData.memberBirthDate.length > 1 &&
  //     this.memberData.memberAddress.length > 1 &&
  //     this.memberData.memberZipCode.length === 4 &&
  //     this.memberData.memberCity.length > 1 &&
  //     (this.memberGenderValue.id === 1 || this.memberGenderValue.id === 2)) {
  //     UsersDataService.getAll('', null, `bruger_brugernavn=${this.memberData.memberUserName}`)
  //       .then((response) => {
  //         this.checkUserNames = response.data

  //         if (this.checkUserNames.length > 0) { // If there already is a user with that username, give a error message
  //           this.$Message.danger({ text: (this.languageValue === 1 ? 'Der er allerede et medlem med det brugernavn, vælg et andet' : 'There already is a member with that username pleace select another') })
  //         } else {
  //           const createUserData = {
  //             bruger_status: true,
  //             bruger_fornavn: this.memberData.memberFirstName,
  //             bruger_efternavn: this.memberData.memberLastName,
  //             bruger_brugernavn: this.memberData.memberUserName,
  //             bruger_password: this.memberData.memberPassword,
  //             bruger_telefon: this.memberData.memberTelephone,
  //             bruger_email: this.memberData.memberEmail,
  //             bruger_brugergruppe_id: 5 // Default, means that the member have the rights as a 'Klubmedlemmer' in the system
  //           }

  //           UsersDataService.create(createUserData)
  //             .then((response) => {
  //               this.lastCreatedUserId = response.data.id
  //               this.memberStart = response.data.created_at
  //               console.log(response.data)

  //               MembersDataService.getAll('medlem_licens:desc', { slicemode: 0, start: 0, limit: 1, page: 0, pagesize: 0, totalcount: 0 })
  //                 .then((response) => {
  //                   this.currentHighestLicenseNumber = response.data[0].medlem_licens

  //                   const createMemberData = {
  //                     medlem_begyndelse: new Date(this.memberStart),
  //                     medlem_foedselsdag: this.memberData.memberBirthDate,
  //                     medlem_vejnummer: this.memberData.memberAddress,
  //                     bypost_id: this.currentByPostId,
  //                     medlem_paradart: this.memberData.memberParaDart,
  //                     bruger_id: this.lastCreatedUserId,
  //                     koen_id: this.memberGenderValue.id,
  //                     medlem_status: true,
  //                     medlem_klubrolle: ClubRoleType.Medlem,
  //                     klubber_id: 22, // Club membership is set to 'Gæsteklubben'
  //                     medlem_licens: this.currentHighestLicenseNumber + 1
  //                   }

  //                   MembersDataService.create(createMemberData)
  //                     .then((response) => {
  //                       this.lastCreatedMemberId = response.data.id
  //                       console.log(response.data)
  //                       this.addLicenseToCart()
  //                       this.submitted = true
  //                       CommonFunctions.scrollToTop()
  //                     })
  //                     .catch((err) => {
  //                       this.error = err
  //                     })
  //                 })
  //                 .catch((err) => {
  //                   this.error = err
  //                 })
  //             })
  //             .catch((err) => {
  //               this.error = err
  //             })
  //         }
  //       })
  //       .catch((err) => {
  //         this.error = err
  //       })
  //   }
  // }

  public addLicenseToCart (orderExtraData: any) : void {
    // If the cart does not exist on locale storage, create it.
    if (!localStorage.getItem('cart')) {
      localStorage.setItem('cart', JSON.stringify([]))
    }

    const cartItems = JSON.parse(localStorage.getItem('cart')!)

    let newItem = {} as SysCart

    newItem = {
      placeInCart: Number(cartItems.length),
      productName: (this.calculateAge(orderExtraData.memberBirthDate) <= 17 ? this.juniorLicenseProduct.produkt_navn : this.seniorLicenseProduct.produkt_navn),
      productDescription: (this.calculateAge(orderExtraData.memberBirthDate) <= 17 ? 'Junior' : 'Senior') + 'licens til: ' + orderExtraData.memberFirstName + ' ' + orderExtraData.memberLastName,
      price: this.calcLicensePrice(orderExtraData.memberBirthDate, this.seniorLicenseProduct.produkt_pris, this.juniorLicenseProduct.produkt_pris),
      dateInCart: new Date(this.nowDate).toISOString().split('T')[0],
      expirationDate: null,
      playerRegistration: null,
      productId: this.calculateAge(orderExtraData.memberBirthDate) <= 17 ? defaultJuniorLicenseId : defaultLicenseId,
      quantity: 1,
      licenseRenewal: { memberID: 0, newLicenseEndDate: this.newLicenseEndDate() },
      clubLicenseRenewal: null,
      eventSignUp: null,
      teamRegistration: null,
      competitionFeeData: null,
      orderExtraData: orderExtraData
    }

    cartItems.push(newItem)

    localStorage.setItem('cart', JSON.stringify(cartItems))

    console.log(localStorage.getItem('cart'))
  }

  public retrieveDateTimeFromServer () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        // this.nowDate = response.data.split('T')[0]
        this.nowDate = new Date(response.data).toISOString().split('T')[0]

        this.seasonHalfWay = new Date(this.nowDate).getFullYear() + '-07-01'
        this.seasonEnd = new Date(this.nowDate).getFullYear() + '-12-01'
        console.log(this.seasonEnd)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public newLicenseEndDate () : string {
    const currentYear = new Date(this.nowDate).getFullYear()

    if (this.nowDate > this.seasonEnd) {
      return (currentYear + 1).toString() + '-12-31'
    }

    return currentYear.toString() + '-12-31'
  }

  public calcLicensePrice (birthDate: string, seniorPrice: number, juniorPrice: number) : number {
    const price = (this.calculateAge(birthDate) <= 17 ? juniorPrice : seniorPrice)

    if (Date.parse(this.nowDate) > Date.parse(this.seasonHalfWay) && Date.parse(this.nowDate) < Date.parse(this.seasonEnd)) {
      return price / 2
    }

    return price
  }

  public calculateAge (birthDate: string) : number {
    const ageDifMs = Date.parse(this.nowDate) - new Date(birthDate).getTime()
    const ageDate = new Date(ageDifMs)

    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  async mounted () : Promise<void> {
    this.retrieveLicenseProduct()
    this.retrieveDateTimeFromServer()
  }
}
